import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 1.4rem;
  padding-bottom: 3rem;
`;

export const Grid = styled.div`
  margin: auto;
  width: ${({len}) => len*15 + `%`};
  display: grid;
  align-items: center;
  grid-template-columns: ${({ len }) => `repeat(` + len + `, 1fr)`};
  grid-template-rows: 8fr;
  gap: 1.2rem 1.2rem;
  @media (max-width: 680px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Item = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  // box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.11);
  border-radius: 1rem;
  background: ${({ theme }) => (theme === 'light' ? '#4fc3f7' : '#4fc3f7')};

  h4 {
    color: ${({ theme }) => (theme === 'light' ? '#212121' : '#fff')};
  }

  p {
    font-size: 14px;
    margin: .5rem;
    color: ${({ theme }) => (theme === 'light' ? 'white' : 'white')};

    @media (max-width: 960px) {
      font-size: 12px;
    }
    @media (max-width: 680px) {
      font-size: 10px;
    }
  }
`;