import styled from 'styled-components';

export const Wrapper = styled.div`
  // padding-bottom: 4rem;
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
  text-align: center;
`;



export const Details = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 30pt;
    text-align: center;
    color: ${({ theme }) => (theme === 'light' ? '#37474f' : '#e6e6e6')};
    margin: 2rem;
    background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 20pt;
    }
  }

  h4 {
    margin-bottom: 1rem;
    font-size: 20pt;
    font-weight: normal;
    color: ${({ theme }) => (theme === 'light' ? '#373737' : '#e6e6e6')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 16pt;
    }
  }

}
  }
`;


export const Section = styled.div`
  width: 70%;
  margin: auto;
  padding-top: 1.4rem;
  

  @media (max-width: 960px) {
    margin-bottom: 2rem;
  }

  h2 {
    padding-bottom: 1rem;
    font-size: 22pt;
    text-align: center;
    color: ${({ theme }) => (theme === 'light' ? 'black' : 'lime')};
    margin: 2rem;
    background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 20pt;
    }
  }

  h4 {
    margin-bottom: 1rem;
    font-size: 20pt;
    font-weight: normal;
    color: ${({ theme }) => (theme === 'light' ? '#373737' : '#e6e6e6')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 16pt;
    }
  }

  p {
    margin-bottom: .5rem;
    text-align: left;
    font-size: 14pt;
    font-weight: normal;
    color: ${({ theme }) => (theme === 'light' ? '#7c7c7c' : '#bfbfbf')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 12pt;
    }
  }
  
  img {
    padding: 1rem;
  }

  li {
    contetn-align: center;
    padding: 0.4rem;
    border-bottom: ${({ theme }) => (theme === 'light' ? 'solid #eceff1 .1rem' : 'solid #424242 .1rem')};
    border-bottom: 
  }
  }
`;