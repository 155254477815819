import React, {useContext} from 'react';
import { Link } from 'gatsby';
import { ThemeContext } from 'providers/ThemeProvider';
import { useIntl } from "gatsby-plugin-intl"
import { Layout, SEO, Button } from 'components/common';
import { Header } from 'components/theme/Header';
import { TechStack } from 'components/projects/';
import { Wrapper, Details, Section } from 'components/projects/styles';
import lms from 'assets/projects/lms-arch.png'
import illustration from 'assets/projects/lms-illustration.png'

const stack = ['Django', 'React', 'GCP', 'Scikit-learn', 'GitHub']

export default () => {
  const { theme } = useContext(ThemeContext);
  const intl = useIntl()
  return (
  <Layout>
    <SEO />
    <Wrapper>
      <Header />
      <Details theme={theme}>
        <h1>Learning Management System</h1>
          <img src={illustration} width='500em' />
        <TechStack stack={stack}/>
        <Section theme={theme}>
          <h2>このプロジェクトについて</h2>
          <ul>
            <li>
              <p>学習サポートアプリ(Learning Management System)をクラウド上に構築</p>
            </li>
            <li>
              <p>Facebook、Google Formで管理していた仕組みをウェブアプリへと移行し自動化することで業務効率を40%以上改善</p>
            </li>
            <li>
              <p>試験対策問題、模擬テスト、動画教材、生徒の管理をアプリケーションに統合することにより利用者のアクセシビリティを大幅に改善</p>
            </li>
          </ul>
          <img src={lms} width='800em' />
        </Section>
        <Section theme={theme}>
          <h2>Details</h2>
          <ul>
            <li>
              <p>GCP上でコスト、スケーラビリティを最適化したソリューションを構築</p>
            </li>
            <li>
              <p>GitHub ActionsによりCI/CDを実現</p>
            </li>
            <li>
              <p>FrontendはReactでモダンなUIのアプリケーション</p>
            </li>
            <li>
              <p>機械学習(AI)を用いて利用者の学習をサポート</p>
            </li>
          </ul>
        </Section>
        <Link to="/contact"><Button theme={theme}>{intl.formatMessage({ id: "get-in-touch" })}</Button></Link>
      </Details>
    </Wrapper>
  </Layout>
  );
};