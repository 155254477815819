import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container } from 'components/common';
import { Wrapper, Grid, Item } from './styles';

export const  TechStack = (props) => {
  const { theme } = useContext(ThemeContext);
    return (
      <Wrapper as={Container} id="projects">
        <Grid len={props.stack.length}>
          {
            props.stack.map((tech) => (
          <Item theme={theme}>
          {/* <Item  as="a" href={} target="_blank" rel="noopener noreferrer" theme={theme}> */}
                <p>{tech}</p>
          </Item>
            ))
          }
        </Grid>
      </Wrapper>
    );
}